import React from "react";
import { Box, Typography } from "@mui/material";
import BigRedNo from "../../assets/FourTeenthPage/BigRedNo.png";
import Revealer from "./../Revealer";


const EstimationPart5 = ({matches}) => {
  return (
    <Box
      mt={matches ? '20vh': '5vh'}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        position: "relative",
      }}
    >

      <Box
        sx={{
          display: "flex",
          flexDirection:"column" ,
          width: "100%",
          justifyContent: "space-around",
          marginTop: 15,
        }}
      >
        <Revealer>
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: { md: "3vw", xs: "5vw" },
              textAlign: { xs: "center", md: "left" },
              m:5
            }}
          >
            OUR FINANCIAL MODEL BENEFITS FROM HIGH INTEREST RATES. BUT WHAT
            HAPPENS IF INTEREST RATES FALL? WILL THE PROJECT COLLAPSE?
          </Typography>
        </Revealer>
        <Revealer>
          <img width={"60%"} src={BigRedNo} style={{ marginTop: "5vh",marginLeft:'auto'}} />
        </Revealer>
      </Box>
    </Box>
  )
}

export default EstimationPart5
