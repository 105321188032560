import React from "react";
import { Box, Typography } from "@mui/material";
import DiscounIcon from "../../assets/FifthPage/DiscountIcon.png";
import LockIcon from "../../assets/FifthPage/LockIcon.png";
import QRCode from "../../assets/FifthPage/QrCode.png";
import UpwardTrendcon from "../../assets/FifthPage/UpwardTrend.png";
import Revealer from "./../Revealer";

const ApplicationFeatures = ({matches}) => {
  return (
    <Box
      mt={'20vh'}
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: { xs: "column" },
      }}
    >
      <Box
        sx={{
          backgroundColor: "#897CD5",
          height: { md: "100vh", xs: "30vh" },
          width: { md: "60%", xs: "100%" },
          borderTopRightRadius: { md: 10000 },
          borderBottomRightRadius: { md: 10000, xs: 50 },
          borderBottomLeftRadius: { xs: 50 },
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: { md: "flex-start", xs: "center" },
        }}
      >
        <Revealer>
          <Typography
            sx={{
              fontSize: { md: "5vw", xs: "8vw" },
              fontWeight: 700,
              letterSpacing: 5,
              letterSpacing: 7,
              marginRight: { md: 10 },
            }}
          >
            APPLICATION FEATURES
          </Typography>
        </Revealer>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: { md: "absolute" },
          right: { md: "2%" },
          height: { md: "100vh" },
          marginBottom: { xs: 10 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { md: "flex-start", xs: "center" },
            alignItems: "center",
          }}
        >
          <Revealer>
            <img
              src={DiscounIcon}
              width={110}
              style={{ marginTop: "2vh" }}
              alt="React Image"
            />
          </Revealer>
          <Revealer>
            <Box
              sx={{
                height: { md: 3, xs: 10 },
                backgroundColor: "#93A361",
                width: { md: 90, xs: 3 },
              }}
            ></Box>
          </Revealer>
          <Box
            sx={{
              display: "flex",
              height: 90,
              borderStyle: "solid",
              borderWidth: 3,
              borderColor: "#93A361",
              backgroundColor: "#897CD5",
              borderRadius: 10,
              width: { md: "30vw", xs: "80%" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Revealer>
              <Typography sx={{ fontSize: 30, textAlign: "center" }}>
                Discount Shopping
              </Typography>
            </Revealer>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: { md: "flex-start", xs: "center" },
            alignItems: "center",
            marginTop: "4vh",
          }}
        >
          <Revealer>
            <img
              src={QRCode}
              width={130}
              style={{ marginLeft: { md: 30, xs: 0 } }}
              alt="React Image"
            />
          </Revealer>
          <Revealer>
            <Box
              sx={{
                backgroundColor: "#93A361",
                marginTop: { md: "4vh" },
                height: { md: 3, xs: 50 },
                backgroundColor: "#93A361",
                width: { md: 90, xs: 3 },
              }}
            ></Box>
          </Revealer>
          <Box
            sx={{
              display: "flex",
              height: 90,
              borderStyle: "solid",
              borderWidth: 3,
              borderColor: "#93A361",
              backgroundColor: "#897CD5",
              borderRadius: 10,
              width: { md: "30vw", xs: "80%" },
              justifyContent: "center",
              alignItems: "center",
              marginTop: { md: "4vh" },
            }}
          >
            <Revealer>
              <Typography sx={{ fontSize: 30, textAlign: "center" }}>
                Payment with QR
              </Typography>
            </Revealer>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: { md: "13vh" },
            flexDirection: { md: "row", xs: "column" },
            width: "100%",
          }}
        >
          <Revealer>
            <img
              src={LockIcon}
              width={90}
              style={{
                marginTop: !matches && "3vh",
                marginBottom: !matches && "3vh",
                marginLeft: matches && 65,
              }}
              alt="React Image"
            />
          </Revealer>
          <Revealer>
            <Box
              sx={{
                height: { md: 3, xs: 50 },
                backgroundColor: "#93A361",
                width: { md: 90, xs: 3 },
                // alignSelf: "center",
              }}
            ></Box>
          </Revealer>
          <Box
            sx={{
              display: "flex",
              height: 90,
              borderStyle: "solid",
              borderWidth: 3,
              borderColor: "#93A361",
              backgroundColor: "#897CD5",
              borderRadius: 10,
              width: { md: "30vw", xs: "80%" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Revealer>
              <Typography sx={{ fontSize: 30 }}>Fast Payment</Typography>
            </Revealer>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <Revealer>
            <img
              src={UpwardTrendcon}
              width={170}
              style={{ marginTop: matches ? "5vh" : "3vh" }}
              alt="React Image"
            />
          </Revealer>
          <Revealer>
            <Box
              sx={{
                height: { md: 3, xs: 50 },
                backgroundColor: "#93A361",
                width: { md: 90, xs: 3 },
                marginTop: { md: "5vh" },
              }}
            ></Box>
          </Revealer>
          <Box
            sx={{
              display: "flex",
              height: 90,
              borderStyle: "solid",
              borderWidth: 3,
              borderColor: "#93A361",
              backgroundColor: "#897CD5",
              borderRadius: 10,
              width: { md: "30vw", xs: "80%" },
              justifyContent: "center",
              alignItems: "center",
              marginTop: { md: "5vh" },
            }}
          >
            <Revealer>
              <Typography sx={{ fontSize: 30, textAlign: "center" }}>
                Easy Marketing for Businesses
              </Typography>
            </Revealer>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ApplicationFeatures
