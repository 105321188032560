import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import BenBuySystem from "../Components/HomePageContents/BenBuySystem";
import ProblemInMarket from "../Components/HomePageContents/ProblemInMarket";
import Solution from "../Components/HomePageContents/Solution";
import Contact from "../Components/HomePageContents/Contact";


const HomePage = () => {
    document.body.style.backgroundColor = "white";

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
  
    return (
      <Box
        sx={{
          position: "relative",
        }}
      >
        <BenBuySystem matches={matches} />
        <ProblemInMarket matches={matches} />
        <Solution matches={matches} />
        <Contact/>
      </Box>
    );
}

export default HomePage
