import { Box, Typography } from "@mui/material";
import table from "../../Resources/11/table.jpg";
import Revealer from "./../Revealer";

const EstimationPart2 = ({ matches }) => {
  return (
    <Box
      height={matches && "100vh"}
      position={"relative"}
      mt={matches ? '20vh': '5vh'}
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        alignItems: { xs: "center" },
      }}
    >
      <Typography
        variant="h2"
        fontFamily={"'Patua One', serif"}
        fontWeight={600}
        sx={{
          position: { md: "absolute", xs: "initial" },
          right: 20,
          top: 20,
          paddingInline: 4,
          paddingBlock: 2,
          backgroundColor: "#f47c00",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          fontSize: { md: "4vw", xs: "8vw" },
          width: { xs: "90%", md: "auto" },
          marginTop: { xs: 2 },
        }}
      >
        PART 2
      </Typography>
      <Box
        height={matches ? "100vh" : "auto"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"space-around"}
        marginTop={!matches && 3}
      >
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          width={"100%"}
          alignItems={"center"}
          marginBottom={!matches && 10}
          sx={{ flexDirection: { md: "row", xs: "column-reverse" } }}
        >
          <Revealer>
            <img
              src={table}
              width={matches ? "80%" : "90%"}
              style={{ zIndex: -100 }}
            />
          </Revealer>
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            width={matches ? "50%" : "100%"}
          >
            <Revealer>
              <Typography
                textAlign={"center"}
                variant="h4"
                fontSize={matches ? "2.2vw" : "5vw"}
              >
                The remaining 14% is put on annual interest. In the side table,
                the cost of the discount rates is subtracted from the interest
                return, so it looks negative, but as we will explain in the
                following pages, this figure becomes positive with the monthly
                return and POS returns.
              </Typography>
            </Revealer>
            <Revealer>
              <Typography
                maxWidth={900}
                fontFamily={"'Patua One', serif"}
                fontWeight={600}
                textAlign={"center"}
                variant="h2"
                marginTop={5}
                fontSize={matches ? "3vw" : "6.5vw"}
              >
                Total profit from annual interest: <br />
                -₺5.544.000
              </Typography>
            </Revealer>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default EstimationPart2
