import { Box, Typography } from "@mui/material";
import picture from "../../Resources/2/Picture4.png";
import table from "../../Resources/10/table.png";
import BenbuyLogo from "../../Resources/benbuylogo.png";
import Revealer from "./../Revealer";

const EstimationPart1 = ({matches}) => {
  return (
    <Box
      height={matches && "100vh"}
      position={"relative"}
      mt={matches ? '20vh': '5vh'}
      sx={{
        display: { xs: "flex" },
        flexDirection: { xs: "column" },
        alignItems: "center",
      }}

    >
      <Typography
        variant="h2"
        fontFamily={"'Patua One', serif"}
        fontWeight={600}
        sx={{
          position: { md: "absolute", xs: "initial" },
          right: 20,
          top: 20,
          paddingInline: 4,
          paddingBlock: 2,
          backgroundColor: "#f47c00",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          fontSize: { md: "4vw", xs: "8vw" },
          width: { xs: "90%", md: "auto" },
          marginTop: { xs: 2 },
        }}
      >
        PART 1
      </Typography>
      <Box
        height={matches && "100vh"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={matches && "space-around"}
        marginTop={!matches && 10}
        marginBottom={!matches && 10}
      >
        <Revealer>
          <Typography
            fontSize={matches ? "3.8vw" : "8vw"}
            fontFamily={"'Patua One', serif"}
            fontWeight={600}
            marginTop={matches && 10}
            marginRight={matches && 30}
            textAlign={"center"}
            sx={{
              width: { md: "80%", xs: "100%" },
            }}
          >
            LET'S ESTIMATE OUR EARNINGS FROM 100,000 USERS
          </Typography>
        </Revealer>
        <Box
          display={"flex"}
          flexDirection={matches ? "row" : "column-reverse"}
          justifyContent={"space-around"}
          width={"100%"}
          alignItems={"center"}
        >
          <Revealer>
            <img src={table} width={matches ? "80%" : "90%"} />
          </Revealer>
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            width={matches ? "50%" : "95%"}
          >
            <Revealer>
              <Typography
                textAlign={"center"}
                variant="h4"
                fontSize={matches ? "2.5vw" : "5vw"}
              >
                The reason for 86% is: 80% of the money invested is spent in 1
                month and we provide an average discount of 7%. 7% of 80% is
                5.6%, so 86% has to be put into monthly interest.
              </Typography>
            </Revealer>
            <Revealer>
              <Typography
                fontFamily={"'Patua One', serif"}
                fontWeight={600}
                textAlign={"center"}
                variant="h2"
                marginTop={matches ? 10 : 2}
                fontSize={matches ? "2.5vw" : "7.5vw"}
              >
                Monthly interest total profit: <br />
                ₺29.412.000
              </Typography>
            </Revealer>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default EstimationPart1
