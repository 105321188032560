import {
    Box,
    Typography,
  } from "@mui/material";
  import QRIMAGE from "../../Resources/1/test.png";
  import Revealer from "./../Revealer";

const BenBuySystem = ({ matches }) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: { xs: "space-between", md: "space-between" },
        position: "relative",
        overflowY: "hidden",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: matches && 5,
            marginTop: matches ? 10 : 30,
            alignItems: !matches && "center",
          }}
        >
          <Revealer>
            <Typography
              variant="h1"
              fontSize={matches ? "6vw" : "20vw"}
              fontFamily={"'Patua One', serif"}
              fontWeight={600}
              textAlign={"left"}
            >
              BENBUY
            </Typography>
          </Revealer>
          <Revealer>
            <Typography
              variant="h4"
              fontSize={matches ? "2vw" : "5vw"}
              fontFamily={"'Patua One', serif"}
              fontWeight={600}
            >
              INNOVATIVE PAYNMENT SYSTEM
            </Typography>
          </Revealer>
          <Box
            sx={{
              marginTop: matches && 10,
              marginLeft: matches && 5,
            }}
          >
            <Revealer>
              <Typography
                textAlign={"left"}
                variant="h4"
                fontFamily={"'Patua One', serif"}
                fontWeight={600}
                fontSize={"4.5vw"}
                color={"#8c62d8"}
              >
                2024
              </Typography>
            </Revealer>
            <Revealer>
              <div
                style={{
                  width: "8vw",
                  height: "2vh",
                  backgroundImage:
                    "linear-gradient(to right, #aa81f3 , #10b2fe)",
                  borderRadius: 20,
                }}
              />
            </Revealer>
          </Box>
        </Box>
      </Box>
      <img
        src={QRIMAGE}
        width={matches ? "40%" : "100%"}
        style={{ alignSelf: matches ? "flex-end" : "center" }}
      />
      {/* <Test style={{ position: "absolute", right: 180, marginBottom: 30 }} /> */}
      {/* <img src={picture1} style={{ position: "absolute", top: 0, right: 0 }} /> */}
    </Box>
  )
}

export default BenBuySystem
