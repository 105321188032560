import {
    Avatar,
    Box,
    Paper,
    Typography,
  } from "@mui/material";
  import bottonImage from "../../Resources/2/bottom.png";
  import picture1 from "../../Resources/2/Picture1.png";
  import picture2 from "../../Resources/2/Picture2.png";
  import picture3 from "../../Resources/2/Picture3.png";
  import Revealer from "./../Revealer";

const ProblemInMarket = ({ matches }) => {

    const data = [
        {
          img: picture1,
          title: "INCREASED FINANCIAL BURDEN",
          desc: "People are experiencing financial difficulties due to the decrease in their purchasing power. This situation makes it difficult for people to make a living.",
        },
        {
          img: picture2,
          title: "SLOWING ECONOMY",
          desc: "Slowing economies increase the value of money, people don't want to miss even the smallest discounts because it is harder to make money.",
        },
        {
          img: picture3,
          title: "BUSINESS SALES CHALLENGES",
          desc: "People's spending habits are decreasing due to high prices and businesses face difficulties in attracting potential customers in free market economies where competition is intense.",
        },
      ];
    

  return (
    <Box
      mt={'10vh'}
      sx={{
        height: { md: "100vh" },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        overflowY: 'visible',
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          marginLeft: { md: "2%" },
          marginBottom: { md: 20 },
          alignItems: { xs: "center", md: "flex-start" },
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <Revealer>
          <Typography
            sx={{
              backgroundColor: "#9695ff",
              float: "left",
              padding: 1,
              paddingInline: 2,
              borderRadius: 2,
              textAlign: "center",
              marginBottom: 5,
              marginTop: { xs: 5 },
              // marginLeft: { md: 10 },
              fontSize: { xs: "8vw", md: "5vw" },
            }}
            color={"white"}
            variant="h3"
            fontFamily={"'Patua One', serif"}
            fontWeight={600}
          >
            PROBLEM
          </Typography>
        </Revealer>
        <Revealer>
          <Typography
            sx={{
              textAlign: { md: "left", xs: "center" },
              textWrap: "wrap",
              // maxWidth: 500,
              marginBottom: 5,
              fontSize: { md: "3vw", xs: "10vw" },
            }}
            variant="h3"
            fontFamily={"'Patua One', serif"}
            fontWeight={600}
          >
            FINANCIAL PRESSURE IN THE MARKET
          </Typography>
        </Revealer>
        <Revealer>
          <Typography
            sx={{
              textAlign: { md: "left", xs: "center" },
              textWrap: "wrap",
              // maxWidth: 500,
              fontSize: { md: "2vw", xs: "5vw" },
            }}
            variant="h5"
          >
            Economic hardship puts a financial burden on people. This lowers
            their standard of living. As a result:
          </Typography>
        </Revealer>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          marginRight: { md: "2%" },
          alignItems: { xs: "center", md: "flex-end" },
        }}
        minHeight={600}
        width={"100%"}
        justifyContent={"space-around"}
        zIndex={1000}
        overflow={'visible'}
      >
        {data.map((item, index) => {
          return (
            <Revealer overflow="none">
              <Paper
                elevation={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  paddingInline: { md: 3, xs: 1 },
                  paddingBlock: { md: 3 },
                  backgroundImage:
                    "linear-gradient(to right, #fdf0f7 , #ffffff)",
                  borderRadius: 7,
                  // maxWidth: 600,
                  width: { xs: "95%" },
                  marginBottom: { xs: 5 },
                }}
              >
                <Avatar
                  sx={{
                    position: "absolute",
                    top: { xs: -10 },
                    left: { md: -35 },
                    width: 50,
                    height: 50,
                    border: "2px solid black",
                    backgroundImage:
                      "linear-gradient(to right, #94e7f9 , #f5e3d2,#edc7d6)",
                    color: "black",
                    fontFamily: "'Patua One', serif",
                    fontWeight: 600,
                  }}
                >
                  {"0" + (index + 1)}
                </Avatar>
                <img src={item.img} style={{ marginRight: 10 }} width={120} />
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    fontFamily={"'Patua One', serif"}
                    fontWeight={600}
                    fontSize={20}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      textWrap: "wrap",
                      maxWidth: 400,
                      minWidth: { xs: "100%" },
                    }}
                  >
                    {item.desc}
                  </Typography>
                </Box>
              </Paper>
            </Revealer>
          );
        })}
      </Box>
      {/* {matches && (
        <img
          style={{
            position: "absolute",
            bottom: 0,
            left: '20vw',
          }}
          src={bottonImage}
        />
      )} */}
      {/* {matches && (
        <img
          style={{ position: "absolute", bottom: 0, right: 0 }}
          src={BenbuyLogo}
        />
      )} */}
      {/* <img style={{ position: "absolute", top: 0, left: 0 }} src={picture4} /> */}
    </Box>
  )
}

export default ProblemInMarket
