import React from "react";
import { Box, Typography } from "@mui/material";
import MarketCar from "../../assets/MarketCar.png";
import Property2 from "../../assets/Property2.png";
import Property3 from "../../assets/Property3.png";
import Property4 from "../../assets/Property4.png";
import Property5 from "../../assets/Property5.png";
import Revealer from "../Revealer";



const WhyBenbuy= ({matches})=>{

    const styleOfFive = {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: 200,
        minWidth: 170,
        marginTop: matches && 4,
        marginBottom: !matches && 4,
      };

    return (
        <Box width={'80vw'} ml={'auto'} mr={'auto'} mt={matches ? '20vh': '5vh'} > 
          <div
            style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 25,
                marginTop:50
            }}
            >
            <div
                style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                }}
            >
                <Revealer>
                <text
                    style={{
                    fontSize: matches ? "5vw" : "10vw",
                    fontWeight: 900,
                    color: "#1B1B40",
                    paddingInline: { md: 2 },
                    }}
                >
                    WHY BENBUY?
                </text>
                </Revealer>
                <Revealer>
                <text style={{ fontSize: 29, fontWeight: 900, color: "#CA53C6" }}>
                    BECAUSE WE SOLVE PROBLEMS!
                </text>
                </Revealer>
            </div>
        </div>
    
        <Box
        sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "space-around",
            alignItems: "center",
        }}
        >
        <Box sx={styleOfFive}>
            <Revealer useStyle={false}>
            <img src={MarketCar} width={100} alt="React Image" />
            </Revealer>
            <Revealer useStyle={false}>
            <Typography
                sx={{
                color: "#1B1B40",
                fontWeight: 900,
                fontSize: 20,
                margin: 1,
                }}
            >
                Products
            </Typography>
            </Revealer>
            <Revealer>
            <Typography sx={{ fontWeight: "bold", fontSize: 13, margin: 1 }}>
                Budget Friendly Shopping with up to %10 Discount
            </Typography>
            </Revealer>
            <Revealer>
            <Typography>
                People will have easier access to quality products while easing
                their financial burden.
            </Typography>
            </Revealer>
        </Box>
    
        <Box sx={styleOfFive}>
            <Revealer useStyle={false}>
            <img src={Property2} width={150} alt="React Image" />
            </Revealer>
            <Revealer useStyle={false}>
            <Typography
                sx={{
                color: "#1B1B40",
                fontWeight: 900,
                fontSize: 20,
                margin: 1,
                }}
            >
                BUSINESS
            </Typography>
            </Revealer>
            <Revealer>
            <Typography sx={{ fontWeight: "bold", fontSize: 13, margin: 1 }}>
                More Customers and Sales Potential
            </Typography>
            </Revealer>
            <Revealer>
            <Typography>
                Businesses will be able to increase their sales thanks to
                increased purchasing power.
            </Typography>
            </Revealer>
        </Box>
    
        <Box sx={{ ...styleOfFive, width: 280 }}>
            <Revealer useStyle={false}>
            <img
                src={Property3}
                width={200}
                style={{ marginRight: 15 }}
                alt="React Image"
            />
            </Revealer>
            <Revealer useStyle={false}>
            <Typography
                sx={{
                color: "#1B1B40",
                fontWeight: 900,
                fontSize: 20,
                margin: 1,
                }}
            >
                SOCIAL DEVELOPMENT
            </Typography>
            </Revealer>
            <Revealer useStyle={false}>
            <Typography sx={{ fontWeight: "bold", fontSize: 13, margin: 1 }}>
                Increasing Purchasing Power
            </Typography>
            </Revealer>
            <Revealer>
            <Typography>People's purchasing power will increase.</Typography>
            </Revealer>
        </Box>
    
        <Box sx={styleOfFive}>
            <Revealer useStyle={false}>
            <img src={Property4} width={150} alt="React Image" />
            </Revealer>
            <Revealer useStyle={false}>
            <Typography
                sx={{
                color: "#1B1B40",
                fontWeight: 900,
                fontSize: 20,
                margin: 1,
                }}
            >
                SUSTAINABILITY
            </Typography>
            </Revealer>
            <Revealer>
            <Typography sx={{ fontWeight: "bold", fontSize: 13, margin: 1 }}>
                Supported Earnings Model
            </Typography>
            </Revealer>
            <Revealer>
            <Typography>
                An earnings model supported by bank interest, advertising revenue
                and growth.
            </Typography>
            </Revealer>
        </Box>
    
        <Box sx={styleOfFive}>
            <Revealer useStyle={false}>
            <img src={Property5} width={130} alt="React Image" />
            </Revealer>
            <Revealer useStyle={false}>
            <Typography
                sx={{
                color: "#1B1B40",
                fontWeight: 900,
                fontSize: 20,
                margin: 1,
                }}
            >
                FAST PAYMENT
            </Typography>
            </Revealer>
            <Revealer useStyle={false}>
            <Typography sx={{ fontWeight: "bold", fontSize: 13, margin: 1 }}>
                Fast Financial Transactions
            </Typography>
            </Revealer>
            <Revealer>
            <Typography>
                Thanks to the technological infrastructure and QR code, financial
                transactions will be managed more easily, quickly and securely
            </Typography>
            </Revealer>
        </Box>
        </Box>
    
        </Box>
      )
}

  export default WhyBenbuy