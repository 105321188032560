import React from "react";
import { Box, Typography } from "@mui/material";
import benBuyTitle from "../../assets/SixthPage/SixthPageTitle.png";
import People from "../../assets/SeventeenthPage/People.png";
import CornerImage from "../../assets/SeventeenthPage/CornerImage2.png";
import Berk from "../../assets/SeventeenthPage/Berk.png";
import Revealer from "./../Revealer";

const ContactInfo = ({matches}) => {
  return (
    <Box
      mt={'30vh'}
      sx={{
        height: "100vh",
        position: "relative",
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          // marginLeft: 5,
          backgroundColor: !matches && "rgba(0,0,0,0.5)",
        }}
      >
        <Revealer>
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: { md: 45, xs: "7vw" },
              backgroundColor: !matches && "white",
              borderTopRightRadius: !matches && 10,
              borderTopLeftRadius: !matches && 10,
            }}
          >
            CONTACT INFORMATION
          </Typography>
        </Revealer>
        <Revealer>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { md: 23, xs: "7vw" },
              marginTop: matches && 2,
              textAlign: { xs: "center", md: "left" },
              backgroundColor: { xs: "white" },
              borderTopRightRadius: !matches && 10,
              borderTopLeftRadius: !matches && 10,
              borderBottomLeftRadius: !matches && 10,
              borderBottomRightRadius: !matches && 10,
            }}
          >
            We are ready to share details and answer your questions
          </Typography>
        </Revealer>

        <Revealer>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              alignItems: { xs: "center" },
            }}
          >
            <img
              src={Berk}
              width={"25%"}
              style={{ borderRadius: 15, minWidth: 250 }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // marginInlineStart: 4,
                backgroundColor: !matches && "rgba(1,1,1,0.5)",
                borderRadius: !matches && 5,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 900,
                  fontSize: 50,
                  // marginInlineStart: 10,
                  marginTop: 5,
                  textAlign: !matches && "center",
                  color: !matches && "white",
                }}
              >
                For inquiries:
              </Typography>

              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 25,
                  // marginInlineStart: 15,
                  textAlign: { md: "right", xs: "center" },
                  marginBottom: 5,
                  color: !matches && "white",
                }}
              >
                BenBuy1@outlook.com
              </Typography>
            </Box>
          </Box>
        </Revealer>
      </Box>
      <img
        src={People}
        height={matches ? "100%" : "120%"}
        style={{ position: !matches && "absolute", zIndex: -1000 }}
      />
    </Box>
  )
}

export default ContactInfo
