import { Box, Typography } from "@mui/material";
import Revealer from "./../Revealer";

const EstimationPart3 = ({matches}) => {
  return (
    <Box
      height={matches && "100vh"}
      position={"relative"}
      mt={matches ? '20vh': '5vh'}
      sx={{
        display: "flex",
        flexDirection: { xs: "column" },
        alignItems: "center",
      }}
    >
      <Typography
        variant="h2"
        fontFamily={"'Patua One', serif"}
        fontWeight={600}
        sx={{
          position: { md: "absolute", xs: "initial" },
          right: 20,
          top: 20,
          paddingInline: 4,
          paddingBlock: 2,
          backgroundColor: "#f47c00",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          fontSize: { md: "4vw", xs: "8vw" },
          width: { xs: "90%", md: "auto" },
          marginTop: { xs: 2 },
        }}
      >
        PART 3
      </Typography>
      <Box
        height={matches && "100vh"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"space-around"}
        marginBottom={!matches && 10}
      >
        <Box display={"flex"} justifyContent={"space-around"} width={"100%"}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            width={"100%"}
          >
            <Revealer width="100%">
              <Typography
                width={"80%"}
                textAlign={"center"}
                variant="h3"
                marginTop={matches ? 10 : 2}
                fontSize={matches ? "3vw" : "6vw"}
              >
                On average, people want to withdraw 2.5% of their money. This
                demand is driven by equity, interest earnings and advertising
                revenue.
              </Typography>
            </Revealer>
            <Revealer>
              <Typography
                width={"80%"}
                textAlign={"center"}
                variant="h3"
                fontSize={matches ? "3vw" : "6vw"}
                marginTop={matches ? 5 : 2}
              >
                We get a 3% commission on every payment. This is the money that
                comes from here:
              </Typography>
            </Revealer>
            <Revealer width="100%">
              <Typography
                width={"80%"}
                fontFamily={"'Patua One', serif"}
                fontWeight={600}
                textAlign={"center"}
                variant="h2"
                fontSize={matches ? "4.1vw" : "7vw"}
                mt={5}
              >
                Monthly yield: <span style={{ marginLeft: 10 }} /> ₺27.000.000{" "}
                <span style={{ marginLeft: matches ? 100 : 10 }} />
                $900.000
                <br />
                Yearly yield: <span style={{ marginLeft: matches && 10 }} />
                ₺324.000.000 <span
                  style={{ marginLeft: matches ? 50 : -1 }}
                />{" "}
                $10.800.000
              </Typography>
            </Revealer>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default EstimationPart3
