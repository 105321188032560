import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BenBuyLogo from "../../assets/BenBuyTitleLogo.png";
import { useLocation, Link } from "react-router-dom";

//pathname will be checked to change the color of the link that represents current page

const LinkComponent = ({ style, children, to, ...props }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Link
      to={to}
      {...props}
      style={{
        cursor: "pointer",
        color: isActive ? "#8C62D8" : "#000000",
        fontSize: isSmallScreen ? "16px" : "24px",
        fontWeight: 600,
        marginInline: 30,
        textDecoration: "none",
        ...style,
      }}
    >
      {children}
    </Link>
  );
};

const HeaderComponent = () => {
  return (
    <Box
      mt={2}
      width={"100vw"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <LinkComponent to={"/"}>
        <img src={BenBuyLogo} height={40} />
      </LinkComponent>
      <Box mt={3}>
        <LinkComponent to={"/"}>Home</LinkComponent>
        <LinkComponent to={"/Work"} marginInline={3}>
          Work
        </LinkComponent>
        <LinkComponent to={"/About"} mr={0}>
          About
        </LinkComponent>
      </Box>
    </Box>
  );
};

const CustomAppBar = () => {
  return (
    <AppBar elevation={0} sx={{ minHeight: 20 }}>
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          height: 40,
          minHeight: 20,
          backgroundColor: "white",
        }}
      >
        <LinkComponent style={{ marginInline: 0 }} to={"/"}>
          <img src={BenBuyLogo} height={40} />
        </LinkComponent>
        <Box ml={"auto"}>
          <LinkComponent to={"/"}>Home</LinkComponent>
          <LinkComponent to={"/Work"} marginInline={4.5}>
            Work
          </LinkComponent>
          <LinkComponent to={"/About"} mr={7}>
            About
          </LinkComponent>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const Header = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return matches ? <CustomAppBar /> : <HeaderComponent />;
};

export default Header;
