import { Box, Container, Paper, Typography } from "@mui/material";
import middleImg from "../../Resources/7/middle.png";
import arrow from "../../Resources/7/arrow.png";
import Revealer from "../Revealer";

const Card = ({ title, desc, titleColor, cardColor, left = 0, right = 0 }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        marginLeft: left,
        marginRight: right,
        width: "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor: titleColor,
          paddingInline: 2,
          borderRadius: 10,
          position: "absolute",
          top: -30,
        }}
      >
        <Revealer>
          <Typography
            variant="h4"
            fontFamily={"'Patua One', serif"}
            fontWeight={600}
            width={"100%"}
            textAlign={"center"}
          >
            {title}
          </Typography>
        </Revealer>
      </Box>
      <Box
        sx={{
          backgroundColor: cardColor,
          paddingInline: 2,
          paddingBlock: 1,
          borderRadius: 10,
          width: "95%",
          textAlign: "center",
          minHeight: 200,
          display: "flex",
          alignItems: "center",
          boxShadow: "0px 20px 20px -30px",
          justifyContent: "center",
        }}
      >
        <Revealer>
          <Typography fontSize={25} width={"100%"}>
            {desc}
          </Typography>
        </Revealer>
      </Box>
    </Box>
  );
};

export default function ModelAttributesMobile({ matches }) {
  return (
    <Box
      mr={"auto"}
      ml={"auto"}
      width={"100%"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h3"
          fontSize={40}
          fontFamily={"'Patua One', serif"}
          fontWeight={600}
          width={200}
          sx={{
            position: "absolute",
            top: "15%",
            right: "20%",
            textAlign: "center",
          }}
        >
          DISCOUNT SHOPPING BUSINESS MODEL
        </Typography>
        <img width={300} src={middleImg} />
      </Box>
      <img
        width={100}
        style={{
          transform: "rotate(-90deg)",
          marginTop: 50,
          marginBottom: 80,
        }}
        src={arrow}
      />
      <Card
        title={"FIRST STEP"}
        desc={"A total of ₺100 is deposited within one month."}
        titleColor={"#cce3b2"}
        cardColor={"#eae4ff"}
      />
      <img
        width={100}
        style={{
          transform: "rotate(-90deg)",
          marginTop: 50,
          marginBottom: 80,
        }}
        src={arrow}
      />
      <Card
        title={"SECOND STEP"}
        desc={"₺80 is spent in a month."}
        titleColor={"#bbbfee"}
        cardColor={"#eae4ff"}
      />
      <img
        width={100}
        style={{
          transform: "rotate(-90deg)",
          marginTop: 50,
          marginBottom: 80,
        }}
        src={arrow}
      />
      <Card
        title={"THIRD STEP"}
        desc={
          "According to researchs, 80% of the money invested in 1 month is spent in 1 month"
        }
        titleColor={"#eec1d3"}
        cardColor={"#eae4ff"}
        // left={"20%"}
      />
      <img
        width={100}
        style={{
          transform: "rotate(-90deg)",
          marginTop: 50,
          marginBottom: 80,
        }}
        src={arrow}
      />
      <Card
        title={"FOURTH STEP"}
        desc={
          "Expenditures made by credit card are paid to the enterprises after 30 days."
        }
        titleColor={"#f3ba92"}
        cardColor={"#eae4ff"}
      />
      <img
        width={100}
        style={{
          transform: "rotate(-90deg)",
          marginTop: 50,
          marginBottom: 80,
        }}
        src={arrow}
      />
      <Card
        title={"FIFTH STEP"}
        desc={
          "Therefore, the ₺80 spent is invested in monthly interest and the money is transferred to the businesses when the monthly interest expires in 30 days."
        }
        titleColor={"#f1dd9e"}
        cardColor={"#eae4ff"}
      />
      <img
        width={100}
        style={{
          transform: "rotate(-90deg)",
          marginTop: 50,
          marginBottom: 80,
        }}
        src={arrow}
      />
      <Card
        title={"SIXTH STEP"}
        desc={"The remaining ₺20 is put on annual interest."}
        titleColor={"#cce3b2"}
        cardColor={"#eae4ff"}
      />
      <img
        width={100}
        style={{
          transform: "rotate(-90deg)",
          marginTop: 50,
          marginBottom: 80,
        }}
        src={arrow}
      />
      <Card
        title={"SEVENTH STEP"}
        desc={
          "A commission of 3% is deducted from each expenditure made by the users."
        }
        titleColor={"#bbbfee"}
        cardColor={"#eae4ff"}
        // right={"20%"}
      />
      <img
        width={100}
        style={{
          transform: "rotate(-90deg)",
          marginTop: 50,
          marginBottom: 80,
        }}
        src={arrow}
      />
      <Card
        title={"EIGHTH STEP"}
        desc={"Advertising within the app generates extra revenue."}
        titleColor={"#9ccee9"}
        cardColor={"#eae4ff"}
      />
    </Box>
  );
}
