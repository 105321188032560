import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";

const Contact = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const LinkComponent = ({ style, children, to, ...props }) => {
    return (
      <Link
        to={to}
        {...props}
        style={{
          cursor: "pointer",
          color: "#000000",
          fontSize: isSmallScreen ? "12px" : "20px",
          fontWeight: 600,
          marginInline: 30,
          textDecoration: "none",
          ...style,
        }}
      >
        {children}
      </Link>
    );
  };

  return (
    <Box
      ml={"auto"}
      mt={"20vh"}
      pt={"3vh"}
      borderTop={"2px solid #EAEAEA"}
      width={"90vw"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box width={12} height={12} sx={{ backgroundColor: "green" }}></Box>
        <Typography
          variant="h6"
          fontSize={isSmallScreen ? "14px" : "22px"}
          mr={"auto"}
          ml={"15px"}
        >
          {" "}
          Contact{" "}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        mt={2}
        mb={"10vh"}
      >
        <LinkComponent>Instagram</LinkComponent>
        <LinkComponent
          marginInline={3}
          to={"https://www.linkedin.com/company/benbuy/"}
        >
          LinkedIn
        </LinkComponent>
        <LinkComponent to={"/About"}>Email</LinkComponent>
      </Box>
    </Box>
  );
};

export default Contact;
