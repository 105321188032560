import React from "react";
import { Box, Typography } from "@mui/material";
import CurvyArrow from "../../assets/FifteenthPage/CurvyArrow.png";
import Revealer from "./../Revealer";

const EstimationPart6 = ({ matches }) => {
  return (
    <Box
      mt={matches ? '20vh': '5vh'}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "hidden",
        position: "relative",
        alignItems: { xs: "center" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: { xs: 20 },
        }}
      >
        <Revealer>
          <Typography
            sx={{
              // marginTop: 10,
              fontWeight: 900,
              fontSize: { md: "2.2vw", xs: "5vw" },
              marginInlineStart: matches && 10,
              textAlign: { md: "left", xs: "center" },
            }}
          >
            IF WE REDUCE THE DISCOUNT RATES WE PROVIDE TO 5%, EVEN 10% ANNUAL
            INTEREST WILL GENERATE VERY GOOD PROFITS. FOR THIS REASON, WE WILL
            REDUCE OUR DISCOUNT RATES WITH THE DECREASE IN INTEREST RATES, BUT
            WHAT IF THE INTEREST RATES FALL BELOW 10%?
          </Typography>
        </Revealer>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: { md: "95%", xs: "100%" },
          justifyContent: { md: "center", xs: "center" },
        }}
      >
        {matches && (
          <Revealer>
            <img
              src={CurvyArrow}
              width={350}
              style={{ marginTop: 50, marginRight: 20 }}
            />
          </Revealer>
        )}
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#F47C00",
            borderRadius: { md: 20, xs: 10 },
            justifyContent: "center",
            alignItems: "center",
            width: { md: "50%", xs: "95%" },
            marginTop: 7,
            marginBottom: { xs: 5 },
          }}
        >
          <Revealer>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { md: "1.5vw", xs: "5vw" },
                width: { md: "90%", xs: "80%" },
                height: "95%",
                textAlign: "center",
              }}
            >
              In such a case, we will switch to a new system. This system will
              be a system where users earn 1 lottery right for every 1000₺
              purchase and at the end of each month, a part of the income from
              interest will be distributed as gifts such as houses, cars,
              phones. This system will also be implemented in developed
              countries because interest rates are low in those countries. I
              would also like to state that our advertising earnings will
              increase in developed countries. For example, US advertising fees
              are 10 times higher than in Turkey.
            </Typography>
          </Revealer>
        </Box>
      </Box>
    </Box>
  )
}

export default EstimationPart6
