import React from "react";
import { Box, Typography } from "@mui/material";
import StartMoving from "../../assets/SixteenthPage/StartMoving.png";
import EventSharing from "../../assets/SixteenthPage/EventSharing.png";
import CompanyAgreements from "../../assets/SixteenthPage/CompanyAgreements.png";
import Advertisement from "../../assets/SixteenthPage/Advertisements.png";
import benBuyTitle from "../../assets/SixthPage/SixthPageTitle.png";
import Revealer from "./../Revealer";

const AlternativeIncome = ({matches}) => {
  return (
    <Box
      mt={'40vh'}
      sx={{
        width: "100%",
        height: matches && "100vh",
        display: "flex",
        position: "relative",
        flexDirection: { md: "row", xs: "column" },
      }}
    >
      {matches && (
        <img
          src={StartMoving}
          style={{
            height: '100vh',
            objectFit: "cover",
          }}
          alt="Start Moving Image"
        />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "73%",
          marginInlineStart: { md: 4 },
          alignItems: !matches && "center",
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "100%",
            display: !matches && "flex",
            flexDirection: !matches && "column",
            alignItems: !matches && "center",
          }}
        >
          <Revealer>
            <Typography
              color={"#6F6F87"}
              sx={{
                fontWeight: 900,
                fontSize: { md: "5vw", xs: "10vw" },
                marginTop: 3,
                lineHeight: 1,
                textAlign: { md: "left", xs: "center" },
              }}
            >
              BUSINESS <br />
              MODEL
            </Typography>
          </Revealer>
          <Revealer>
            <Typography
              color={"#6F6F87"}
              sx={{
                fontWeight: 900,
                fontSize: 40,
                marginBottom: 5,
                textAlign: { md: "left", xs: "center" },
              }}
            >
              ALTERNATIVE INCOME
            </Typography>
          </Revealer>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            juftifyContent: "space-around",
          }}
        >
          <Revealer>
            <img src={EventSharing} style={{ width: "85%" }} />
          </Revealer>
          <Revealer>
            <img src={CompanyAgreements} style={{ width: "85%" }} />
          </Revealer>
          <Revealer>
            <img src={Advertisement} style={{ width: "85%" }} />
          </Revealer>
        </Box>

        <Box
          sx={{
            // marginInlineStart: 10,
            marginTop: 2,
            textAlign: "center",
            borderBottomWidth: 3,
            borderBottomColor: "#F8702B",
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "2vw", xs: "7vw" },
              fontWeight: "bold",
              width: "100%",
            }}
          >
            With the increase in the number of users, additional income methods
            will be created with event sharing, company agreements and
            advertisements within the application.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default AlternativeIncome
