import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import WhyBenbuy from "../Components/WorkPageContents/WhyBenBuy";
import ModelAttributes from "../Components/WorkPageContents/ModelAttributes";
import DiscountRates from "../Components/WorkPageContents/DiscountRates";
import AvgDiscount from "../Components/WorkPageContents/AvgDiscount";
import EstimationPart1 from "../Components/WorkPageContents/EstimationPart1";
import EstimationPart2 from "../Components/WorkPageContents/EstimationPart2";
import EstimationPart3 from "../Components/WorkPageContents/EstimationPart3";
import EstimationPart4 from "../Components/WorkPageContents/EstimationPart4";
import EstimationPart5 from "../Components/WorkPageContents/EstimationPart5";
import EstimationPart6 from "../Components/WorkPageContents/EstimationPart6";
import Contact from "../Components/HomePageContents/Contact";
import ModelAttributesMobile from "../Components/WorkPageContents/ModelAttributesMobile";

const WorkPage = () => {
  document.body.style.backgroundColor = "white";

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <WhyBenbuy matches={matches} />
      {matches ? (
        <ModelAttributes matches={matches} />
      ) : (
        <ModelAttributesMobile matches={matches} />
      )}
      <DiscountRates matches={matches} />
      <AvgDiscount matches={matches} />
      <EstimationPart1 matches={matches} />
      <EstimationPart2 matches={matches} />
      <EstimationPart3 matches={matches} />
      <EstimationPart4 matches={matches} />
      <EstimationPart5 matches={matches} />
      <EstimationPart6 matches={matches} />
      <Contact />
    </Box>
  );
};

export default WorkPage;
