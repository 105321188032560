import { Box, Typography } from "@mui/material";
import arrow from "../../Resources/fixArrow.png";
import Revealer from "../Revealer";


const AvgDiscount = ({matches}) => {

  return (
    <Box position={"relative"} sx={{ overflow: "hidden" }} mt={30} mb={20} >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-around"}
        alignItems={"center"}
        minHeight={'750px'}
      >
        <Revealer overflow="none">
          <Typography
            variant="h4"
            textAlign={"center"}
            fontFamily={"'Patua One', serif"}
            fontWeight={600}
            sx={{
              fontSize: { xs: "5.5vw", md: "1.8vw" },
              paddingInline: { xs: "5%" },
              display: "flex",
              alignItems: "center",
              width: { md: "50%", xs: "90%" },
              height: 200,
              backgroundImage: "linear-gradient(to right, #5270ff , #fc66c5)",
              borderRadius: 20,
            }}
          >
            According to the researchs, 60% of the total expenditures consist of
            grocery expenditures, while other expenditures account for 40%.
          </Typography>
        </Revealer>
        <Revealer overflow="none">
          <img
            height={'50%'}
            src={arrow}
          />
        </Revealer>
        <Revealer overflow="none">
          <Typography
            variant="h4"
            textAlign={"center"}
            fontFamily={"'Patua One', serif"}
            fontWeight={600}
            sx={{
              fontSize: { xs: "5.5vw", md: "2vw" },
              paddingInline: { xs: "5%" },
              display: "flex",
              alignItems: "center",
              width: { md: "70%", xs: "90%" },
              height: 200,
              backgroundImage: "linear-gradient(to right, #5270ff , #fc66c5)",
              borderRadius: 20,
            }}
          >
            Since we offer 5% discount for grocery shopping and 10% discount for
            other purchases, our average discount rate is 7% when we look at it
            in proportion to the expenditure.
          </Typography>
        </Revealer>
      </Box>
    </Box>
  )
}

export default AvgDiscount
