import { Box, Typography } from "@mui/material";
import arrow from "../../Resources/8/arrow.png";
import Revealer from "./../Revealer";

const DiscountRates = ({matches}) => {
    console.log(matches)
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      position={"relative"}
      alignItems={"center"}
      mt={matches ? '20vh': '5vh'}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 25,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            backgroundImage: "linear-gradient(to right, #a67aff , #fca979)",
            padding: 120,
            borderRadius: 99999,
            zIndex: -10,
            left:45
          }}
        >
          {matches && (
            <>
              <img
                width={250}
                src={arrow}
                style={{ position: "absolute", right: -270, top: 80 }}
              />
              <img
                width={250}
                src={arrow}
                style={{
                  position: "absolute",
                  left: -270,
                  top: 80,
                  transform: "scaleX(-1)",
                }}
              />
            </>
          )}
        </div>
        <Revealer>
          <Typography
            variant="h3"
            fontFamily={"'Patua One', serif"}
            fontWeight={600}
            textAlign={"center"}
            sx={{ textWrap: "wrap", width: 300 }}
            ml={2.4}
          >
            DISCOUNT RATES
          </Typography>
        </Revealer>
      </Box>
      <Box
        display={"flex"}
        sx={{ flexDirection: { md: "row", xs: "column" } }}
        justifyContent={"space-between"}
        marginTop={10}
        height={matches ? 400 : "100%"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"space-around"}
          height={!matches ? 450 : "auto"}
        >
          <Revealer>
            <Typography
              variant="h4"
              fontFamily={"'Patua One', serif"}
              fontWeight={600}
              sx={{
                textWrap: "wrap",
                width: { md: 400, xs: "98%" },
                height: 100,
                display: "flex",
                textAlign: "center",
                backgroundImage: "linear-gradient(to right, #5270ff , #fc66c5)",
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              GROCERY SHOPPING
            </Typography>
          </Revealer>
          <Revealer>
            <Typography
              variant="h4"
              fontFamily={"'Patua One', serif"}
              fontWeight={600}
              sx={{
                textWrap: "wrap",
                width: { md: 300, xs: "80%" },
                textAlign: "center",
                backgroundImage: "linear-gradient(to right, #ff8ad0 , #ffe480)",
                borderRadius: 5,
              }}
              
            >
              5% discount for every grocery transactions
            </Typography>
          </Revealer>
          <Typography
            variant="p"
            fontSize={20}
            sx={{
              width: { md: "90%", xs: "80%" },
              textWrap: "wrap",
              textAlign: "center",
            }}
          >
            (Since food taxes are low in Turkey, the discount we provide cannot
            exceed 5%, otherwise malicious use will start).
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"space-around"}
          height={!matches ? 400 : "auto"}
        >
          <Revealer>
            <Typography
              variant="h4"
              fontFamily={"'Patua One', serif"}
              fontWeight={600}
              sx={{
                textWrap: "wrap",
                width: { md: 400, xs: "98%" },
                height: 100,
                display: "flex",
                textAlign: "center",
                backgroundImage: "linear-gradient(to right, #5270ff , #fc66c5)",
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              MISCELLANEO US EXPENSES
            </Typography>
          </Revealer>
          <Revealer>
            <Typography
              variant="h4"
              fontFamily={"'Patua One', serif"}
              fontWeight={600}
              sx={{
                textWrap: "wrap",
                width: { md: 300, xs: "80%" },
                textAlign: "center",
                height: 150,
                backgroundImage: "linear-gradient(to right, #ff8ad0 , #ffe480)",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
              }}
            >
              10% discount for all other transactions
            </Typography>
          </Revealer>
          <Typography
            variant="p"
            fontSize={20}
            sx={{ width: "90%", textWrap: "wrap", textAlign: "center" }}
          >
            (Since product taxes in Turkey are 20%, they cannot use the 10%
            discount we provide in bad faith).
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default DiscountRates
