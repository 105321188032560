import { Box, Typography } from "@mui/material";
import picture1 from "../../Resources/3/Picture1.png";
import Revealer from "./../Revealer";

const   Solution = ({matches}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      position={"relative"}
      mt={'10vh'}
      sx={{ overflowY: "hidden", height: { md: "100vh" } }}
    >
      <Box
        sx={{
          alignSelf: { md: "flex-end", xs: "center" },
          marginRight: { md: 15 },
          alignItems: { xs: "center" },
        }}
        display={"flex"}
        flexDirection={"column"}
        marginTop={5}
        zIndex={!matches && 100}
        mb={{md:6}}
      >
        <Revealer>
          <Typography
            variant="h1"
            fontFamily={"'Patua One', serif"}
            fontWeight={600}
            sx={{
              fontSize: { md: "5vw", xs: "10vw" },
            }}
          >
            SOLUTION
          </Typography>
        </Revealer>
        <div
          style={{
            width: 150,
            height: 20,
            backgroundImage: "linear-gradient(to right, #aa81f3 , #10b2fe)",
            borderRadius: 20,
            alignSelf: "flex-end",
          }}
        />
      </Box>
      <Box
        sx={{
          flexDirection: { md: "row", xs: "column-reverse" },
          alignItems: { xs: "center" },
        }}
        display={"flex"}
        justifyContent={!matches ? "center" : "space-between"}
        height={"60%"}
        zIndex={!matches && 10}
      >
        <img
          src={picture1}
          width={!matches ? "100%" : "40%"}
          style={{ alignSelf: "flex-end" }}
        />
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            width: { xs: "90%", md: "50%" },
            marginTop: { xs: 5 },
            alignItems: "center",
          }}
        >
          <Revealer>
            <Typography
              fontFamily={"'Patua One', serif"}
              fontWeight={600}
              sx={{
                backgroundColor: "#aa81f3",
                color: "white",
                padding: 2,
                borderRadius: 10,
                float: "left",
                maxWidth: 300,
                textAlign: "center",
                fontSize: { md: "2.5vw", xs: "5vw" },
              }}
            >
              Individuals
            </Typography>
          </Revealer>
          <Revealer>
            <Typography
              sx={{
                textWrap: "wrap",
                maxWidth: 800,
                marginTop: 2,
                fontSize: { md: "1.2vw", xs: "4vw" },
              }}
            >
              Thanks to its efficient financial system, BenBuy offers a payment
              system that provides 5% discount on your grocery purchases and 10%
              discount on other purchases. In this way, people can meet their
              needs at more affordable prices.
            </Typography>
          </Revealer>
          <Revealer>
            <Typography
              fontFamily={"'Patua One', serif"}
              fontWeight={600}
              sx={{
                backgroundColor: "#f47c00",
                color: "white",
                padding: 2,
                borderRadius: 10,
                float: "left",
                maxWidth: 300,
                textAlign: "center",
                marginTop: 10,
                fontSize: { md: "2.5vw", xs: "5vw" },
              }}
            >
              Businesses
            </Typography>
          </Revealer>
          <Revealer>
            <Typography
              sx={{
                textWrap: "wrap",
                marginTop: 2,
                maxWidth: 800,
                fontSize: { md: "1.2vw", xs: "4vw" },
              }}
            >
              It makes it easier for businesses to make sales by offering
              discounted shopping opportunities to users. In this way,
              businesses have the potential to attract more customers and
              increase their sales.
            </Typography>
          </Revealer>
        </Box>
      </Box>
      {/* <img style={{ position: "absolute", top: 0, left: 0 }} src={picture4} /> */}
      {/* <img style={{ position: "absolute", top: 0, right: 0 }} src={picture3} /> */}
      {/* {matches && (
        <img
          width={500}
          style={{ position: "absolute", bottom: 0, right: 0, zIndex: -100 }}
          src={picture5}
        />
      )} */}
    </Box>
  )
}

export default Solution
