import React from "react";
import { Box, Typography } from "@mui/material";
import Revealer from "./../Revealer";

const EstimationPart4 = ({matches}) => {

  const h1style = {
    fontWeight: 900,
    fontSize: matches ? "3.5vw" : "6vw",
    marginTop: 2,
    textAlign: "center",
  };

  return (
    <Box
      mt={matches ? '20vh': '5vh'}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: { md: "right", xs: "center" },
          zIndex: { xs: 1000 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#F47C00",
            borderRadius: 20,
            justifyContent: "center",
            alignItems: "center",
            height: 100,
            marginTop: 3,
            width: matches ? "25vw" : "80%",
          }}
        >
          <Typography
            sx={{
              color: "Black",
              fontSize: { md: "2.8vw", xs: "8vw" },
              fontWeight: 900,
              letterSpacing: 1,
            }}
          >
            CONCLUSION
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          height: "95%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Revealer>
          <Typography sx={h1style}>USER: 100,000 ACTIVE USERS</Typography>
        </Revealer>
        <Revealer>
          <Typography sx={h1style}>
            DAILY EXPENDITURE: 300₺ {"("}10${")"}
          </Typography>
        </Revealer>
        <Revealer>
          <Typography sx={h1style}>
            MONTHLY PROFIT: ₺50.868.000 {"("}$1.695.600{")"}
          </Typography>
        </Revealer>
        <Revealer>
          <Typography sx={h1style}>
            ANNUAL PROFIT: ₺610.416.000 {"("}$20.347.200{")"}
          </Typography>
        </Revealer>
        <Revealer>
          <Typography sx={h1style}>1$=30₺</Typography>
        </Revealer>
      </Box>
    </Box>
  )
}

export default EstimationPart4
