import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ApplicationFeatures from "../Components/AboutPageContents/ApplicationFeatures";
import AlternativeIncome from "../Components/AboutPageContents/AlternativeIncome";
import ContactInfo from "../Components/AboutPageContents/ContactInfo";


const AboutPage = () => {
    document.body.style.backgroundColor = "white";

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
  
    return (
      <Box
        sx={{
          position: "relative",
        }}
      >
        <ApplicationFeatures matches={matches} />
        <AlternativeIncome matches={matches} />
        <ContactInfo key={'cont'} matches={matches} />
      </Box>
    );
}

export default AboutPage
