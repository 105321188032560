import { Box, Typography } from "@mui/material";
import middleImg from "../../Resources/7/middle.png";
import arrow from "../../Resources/7/arrow.png";
import Revealer from "./../Revealer";

const ModelAttributes = ({ matches }) => {
  const Card = ({
    title,
    desc,
    titleColor,
    cardColor,
    left = 0,
    right = 0,
  }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          marginLeft: left,
          marginRight: right,
        }}
      >
        <Box
          sx={{
            backgroundColor: titleColor,
            paddingInline: 2,
            borderRadius: 10,
            position: "absolute",
            top: -20,
          }}
        >
          <Revealer>
            <Typography
              variant="h6"
              fontFamily={"'Patua One', serif"}
              fontWeight={600}
              width={"100%"}
              textAlign={"center"}
            >
              {title}
            </Typography>
          </Revealer>
        </Box>
        <Box
          sx={{
            backgroundColor: cardColor,
            paddingInline: 2,
            paddingBlock: 1,
            borderRadius: 10,
            width: "80%",
            textAlign: "center",
            minHeight: 150,
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 20px 20px -30px",
            justifyContent: "center",
          }}
        >
          <Revealer>
            <Typography fontSize={20} width={"100%"}>
              {desc}
            </Typography>
          </Revealer>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      height={"100vh"}
      ml={"auto"}
      mr={"auto"}
      width={"90%"}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        marginTop: "30vh",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        minHeight={"80vh"}
        justifyContent={"space-around"}
      >
        <Card
          title={"EIGHTH STEP"}
          desc={"Advertising within the app generates extra revenue."}
          titleColor={"#9ccee9"}
          cardColor={"#eae4ff"}
        />
        <Card
          title={"SEVENTH STEP"}
          desc={
            "A commission of 3% is deducted from each expenditure made by the users."
          }
          titleColor={"#bbbfee"}
          cardColor={"#eae4ff"}
          // right={"20%"}
        />
        <Card
          title={"SIXTH STEP"}
          desc={"The remaining ₺20 is put on annual interest."}
          titleColor={"#cce3b2"}
          cardColor={"#eae4ff"}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        justifyContent={"space-around"}
        alignItems={"center"}
      >
        <Card
          title={"FIRST STEP"}
          desc={"A total of ₺100 is deposited within one month."}
          titleColor={"#cce3b2"}
          cardColor={"#eae4ff"}
        />
        {matches && (
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              width={70}
              style={{ position: "absolute", left: -100 }}
              src={arrow}
            />
            <img
              width={70}
              style={{
                position: "absolute",
                right: -120,
                transform: "rotate(180deg)",
              }}
              src={arrow}
            />
            <img
              width={70}
              style={{
                position: "absolute",
                top: -60,
                transform: "rotate(90deg)",
              }}
              src={arrow}
            />
            <img
              width={70}
              style={{
                position: "absolute",
                bottom: -40,
                transform: "rotate(-90deg)",
              }}
              src={arrow}
            />
            <img
              width={70}
              style={{
                position: "absolute",
                bottom: -20,
                left: -90,
                transform: "rotate(-40deg)",
              }}
              src={arrow}
            />
            <img
              width={70}
              style={{
                position: "absolute",
                bottom: -20,
                right: -90,
                transform: "rotate(220deg)",
              }}
              src={arrow}
            />
            <img
              width={70}
              style={{
                position: "absolute",
                top: -20,
                right: -90,
                transform: "rotate(140deg)",
              }}
              src={arrow}
            />
            <img
              width={70}
              style={{
                position: "absolute",
                top: -20,
                left: -90,
                transform: "rotate(40deg)",
              }}
              src={arrow}
            />
            <Typography
              variant="subtitle2"
              fontSize={22}
              fontFamily={"'Patua One', serif"}
              fontWeight={600}
              width={200}
              sx={{
                position: "absolute",
                top: "15%",
                right: "3%",
                textAlign: "center",
              }}
            >
              DISCOUNT SHOPPING BUSINESS MODEL
            </Typography>
            <img width={200} src={middleImg} />
          </Box>
        )}
        <Card
          title={"FIFTH STEP"}
          desc={
            "Therefore, the ₺80 spent is invested in monthly interest and the money is transferred to the businesses when the monthly interest expires in 30 days."
          }
          titleColor={"#f1dd9e"}
          cardColor={"#eae4ff"}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        minHeight={"80vh"}
        justifyContent={"space-around"}
      >
        <Card
          title={"SECOND STEP"}
          desc={"₺80 is spent in a month."}
          titleColor={"#bbbfee"}
          cardColor={"#eae4ff"}
        />
        <Card
          title={"THIRD STEP"}
          desc={
            "According to research, 80% of the money invested in 1 month is spent in 1 month"
          }
          titleColor={"#eec1d3"}
          cardColor={"#eae4ff"}
          // left={"20%"}
        />
        <Card
          title={"FOURTH STEP"}
          desc={
            "Expenditures made by credit card are paid to the enterprises after 30 days."
          }
          titleColor={"#f3ba92"}
          cardColor={"#eae4ff"}
        />
      </Box>
    </Box>
  );
};

export default ModelAttributes;
